import React from 'react';
import { AUTH_ROUTES } from 'domain/auth';
import { Page, Tabs } from '@when-fertility/shared/domain/common';
import { QuestionnaireSummary } from '@when-fertility/shared/domain/questionnaire/questionnaire-summary.component';
import { SettingsSummary } from '../components/setting-summary.component';
import { QuestionnaireProvider } from '@when-fertility/shared/domain/questionnaire/questionnaire.provider';

export const UserProfilePage = () => {
  return (
    <>
      <Page hasPadding={false} className="bg-white md:bg-silver-40" headerClassName="bg-silver-100 md:bg-transparent pb-8">
        <div className="pb-12 lg:px-24">
          <div className="pl-5 md:pl-0 text-3xl md:text-5xl pt-12 md:pt-20 pb-6 max-w-7xl w-full text-charcoal">My Account</div>
          <Tabs
            tabs={[
              { title: 'Settings', icon: 'cog', content: <SettingsSummary /> },
              { title: 'Logout', content: 'Logout content', icon: 'arrow-right', isLink: true, href: `${AUTH_ROUTES.logout}` },
            ]}
          />
        </div>
      </Page>
    </>
  );
};
