import { Input } from '@when-fertility/shared/domain/common';
import React from 'react';
import { useQuestionnaire } from '@when-fertility/shared/domain/questionnaire/questionnaire.provider';

export const QuizInput = () => {
  const { currentQuestion, formData, updateField } = useQuestionnaire();

  return (
    <div className="relative flex items-center">
      <Input
        value={formData[currentQuestion.key]?.value || ''}
        type="text"
        onChange={({ target }) => updateField({ id: currentQuestion.key, value: { value: target.value } })}
        placeholder={currentQuestion.placeholder || ''}
      />
      <span className="right-0 mr-3 absolute">{currentQuestion.fieldLabel}</span>
    </div>
  );
};
