import React, { useMemo, useState } from 'react';
import { Quiz, QuizHeader } from '@when-fertility/shared/domain/questionnaire/components';
import { QuestionnaireProvider } from '@when-fertility/shared/domain/questionnaire/questionnaire.provider';
import { Button, Icon, Page, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { QUIZ_ROUTES } from './quiz.routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '../dashboard';
import gql from 'graphql-tag';
import { testKitService } from '../test-kit';
import { useQuizPageFindByIdQuery } from '@when-fertility/shared/gql/graphql';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common/components';
export const QuizPage = () => {
  const [searchParams] = useSearchParams();
  const testKitId = useMemo(() => searchParams.get(URL_PARAMETERS.testKitId), [searchParams]);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const { data, loading } = useQuizPageFindByIdQuery({ variables: { input: { id: testKitId! } }, fetchPolicy: 'cache-and-network' });

  const testKit = useMemo(() => data?.findByID, [data]);
  const isTestKitActivated = useMemo(() => testKitService.getIsTestKitActivated(testKit?.status), [testKit?.status]);

  if (!testKit && !loading) {
    return <div>Can't find this test kit, please contact support.</div>;
  }

  return (
    <>
      <Page isCentred variant="bright">
        <div className="w-full md:w-96 p-6">
          <div className="text-center">
            {isTestKitActivated && (
              <>
                <div className="text-2xl">Your kit has been activated!</div>
                <div className="text-md font-bold">Now, let&apos;s complete your questionnaire.</div>
              </>
            )}
            {!isTestKitActivated && (
              <>
                <div className="text-2xl">Complete your questionnaire</div>
              </>
            )}
            <div className="mt-6">
              We're about to ask you some questions to help us understand your overall fertility picture a little better. Test results are really
              helpful but need to be considered with other factors too.
            </div>
            <div className="mt-4">It's all straightforward and should only take about 2 minutes.</div>
            <div className="mt-4">Ready when you are!</div>
          </div>
          <div className="absolute bottom-12 p-6 sm:p-0 left-0 md:left-auto flex flex-col w-full md:w-96">
            <div className="text-center text-sm mb-4 text-charcoal-60">
              <Icon height={19} icon="clock" /> Takes 2 minutes
            </div>
            <Button onClick={() => setIsOverlayOpen(true)}>Let&apos;s Go</Button>
            <Button variant="secondary" className="mt-2" onClick={() => navigate(DASHBOARD_ROUTES.root)}>
              I&apos;ll do this later
            </Button>
          </div>
        </div>
        {testKit?.id && (
          <QuestionnaireProvider
            testKitId={testKit?.id}
            saveOnFinish
            onFinish={() => {
              navigate(QUIZ_ROUTES.complete);
            }}
          >
            <FullScreenModalComponent
              isOpen={isOverlayOpen}
              setIsOpen={setIsOverlayOpen}
              customHeader={<QuizHeader rootRoute={DASHBOARD_ROUTES.root} setIsModalOpen={setIsOverlayOpen} />}
            >
              <Quiz />
            </FullScreenModalComponent>
          </QuestionnaireProvider>
        )}
      </Page>
    </>
  );
};

QuizPage.fragments = {
  testKits: gql`
    fragment QuizPageTestKitFragment on TestKit {
      id
      status
    }
  `,
};

QuizPage.query = gql`
  ${QuizPage.fragments.testKits}

  query QuizPageFindById($input: ByIdInput) {
    findByID(input: $input) {
      ...QuizPageTestKitFragment
    }
  }
`;
