import { GqlQuestionnaireDetailsFieldKey } from '@when-fertility/shared/gql/graphql';

export type KitType = 'OVARIAN_RESERVE'; // TODO: this might be done differently in the future but will do for now

export type KitQuestionnaireQuestions = {
  kit_type: KitType;
  questions: QuestionnaireQuestion[];
};

export type QuestionnaireQuestionFieldKey = GqlQuestionnaireDetailsFieldKey | 'INFO';

export type QuestionnaireQuestion = {
  key: string;
  type: 'select' | 'text' | 'textarea' | 'info' | 'multi-select';
  showIfSelected?: Partial<Record<string, string[]>>;
  showCommentIfSelected?: string;
  isRequiredToCompleteProfile?: boolean;
  title: string;
  label: string; // If this is empty, the question might be hidden in places
  blurb?: string;
  options?: Record<string, string>; // This will be empty if type is !select. Can probably type this properly
  fieldLabel?: string;
  placeholder?: string;
};
