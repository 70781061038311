import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useDashboardPageGetUserQuery, useDashboardGetTestKitsQuery, GqlDashboardTestKitFragmentFragment } from '@when-fertility/shared/gql/graphql';
import { Link } from 'react-router-dom';
import { BlobIcon, Card, Icon, Page } from '@when-fertility/shared/domain/common';
import { RESULT_ROUTES } from '../results';
import { USER_ROUTES } from '../user';
import { TestKitSummary } from './components/test-kit-summary.component';
import { testKitService } from '../test-kit';

const PageContainer = ({ firstName, children }: { firstName: string | null; children: React.ReactNode }) => (
  <Page
    title={firstName ? `Welcome ${firstName}` : 'Welcome'}
    headerClassName="bg-wave-sky-mobile sm:bg-wave-sky-standard 2xl:bg-wave-sky-wide bg-bottom bg-cover bg-no-repeat h-40 sm:h-64 text-white"
    hasPadding={true}
  >
    <div className="flex mt-10 sm:mt-2 sm:my-8 flex-col md:flex-row md:justify-between gap-7">{children}</div>
  </Page>
);

const ConditionalLink = ({ showLink, to, children, className }: { showLink: boolean; to: string; children: React.ReactNode; className?: string }) => {
  if (showLink) {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  }
  return <span className={className}>{children}</span>;
};

export const DashboardPage = () => {
  const { data, error, loading: loadingUser } = useDashboardPageGetUserQuery();
  const { data: testKitData, error: testKitError, loading: loadingTestKits } = useDashboardGetTestKitsQuery({ errorPolicy: 'all' });

  const { firstName } = useMemo(() => {
    return {
      firstName: data?.userProfile.firstName || '',
    };
  }, [data]);

  const latestTestKit: GqlDashboardTestKitFragmentFragment | null = useMemo(
    () => testKitService.getLatestTestKit<GqlDashboardTestKitFragmentFragment>(testKitData?.testKits.testKits),
    [testKitData]
  );
  const hasGeneratedReport = useMemo(() => latestTestKit && latestTestKit.results?.find((result) => result.report), [testKitData]);

  if (error) {
    return <PageContainer firstName={firstName}>{error.message}</PageContainer>;
  }

  if (loadingUser || loadingTestKits) {
    return <PageContainer firstName={firstName}>Loading...</PageContainer>;
  }

  let content = "Once you've activated your test kit and completed your questionnaire, you'll be able to change any answers you need to here.";
  let showLink = false;
  if (latestTestKit) {
    if (!hasGeneratedReport) {
      content =
        "We use your answers to these questions to personalise your reports. It's important they're correct. You can update any of your answers here up until we receive your results back from the laboratory.";
      showLink = true;
    } else {
      if (latestTestKit.resultsStatus === 'RECEIVED_NOT_RELEASED') {
        content =
          "We've received your results back from the laboratory and are busy preparing your reports. You are no longer able to update your profile here, you will need to contact our client service team at hello@whenfertility.com.au";
      } else {
        content =
          "We've received your results back from the laboratory. You are no longer able to update your profile here. You will be able to fill out a new questionnaire on your next test kit.";
      }
    }
  }

  return (
    <PageContainer firstName={firstName}>
      <div className="w-full md:w-2/3">
        <TestKitSummary />
      </div>
      <div className="w-full md:w-1/3">
        <div className="mb-6">
          <Card>
            <Link className="flex justify-between items-center" to={RESULT_ROUTES.root}>
              <div className="flex items-center">
                <BlobIcon icon="result" variant="filled" blobType="6" iconHeight={18} iconWidth={17} />
                <div className="ml-3">Kit History</div>
              </div>
              <Icon icon="arrow-right" width={30} height={23}></Icon>
            </Link>
            <div className="text-sm mt-4">You can find all of the test kits you've ordered and taken here.</div>
          </Card>
        </div>
        <div className="mb-6">
          <Card>
            <ConditionalLink
              showLink={showLink}
              className="flex justify-between items-center"
              to={`${USER_ROUTES.questionnaire}?testKitId=${latestTestKit?.id}`}
            >
              <div className="flex items-center">
                <BlobIcon icon="user" variant="filled" blobType="7" iconHeight={18} iconWidth={14} />
                <div className="ml-3">My Questionnaire</div>
              </div>
              {showLink && <Icon icon="arrow-right" width={30} height={23}></Icon>}
            </ConditionalLink>
            <div className="text-sm mt-4">{content}</div>
          </Card>
        </div>
      </div>
    </PageContainer>
  );
};

DashboardPage.fragments = {
  user: gql`
    fragment DashboardPageUserFragment on User {
      id
      firstName
      lastName
    }
  `,
  testKits: gql`
    fragment DashboardTestKitFragment on TestKit {
      id
      status
      isSampleCollected
      dateSampleCollected
      dateResultsReceived
      resultsStatus
      results {
        report {
          referenceRange
        }
      }
    }
  `,
};

DashboardPage.query = gql`
  ${DashboardPage.fragments.user}
  ${DashboardPage.fragments.testKits}

  query DashboardPageGetUser {
    userProfile {
      ...DashboardPageUserFragment
    }
  }

  query DashboardGetTestKits($testKitInput: TestKitInput) {
    testKits(input: $testKitInput) {
      testKits {
        ...TestKitSummaryTestKitFragment
      }
    }
  }
`;
