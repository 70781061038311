import { TestKitResult, TestKitResultAgeGroup, TestKitResultReferenceRange, TestKitResultReferenceRangeValues } from './result.model';
import { AMH_REFERENCE_RANGES, REPORT_NOTES } from './test-kit.constant';
import { QuestionnaireDetail } from '@when-fertility/server/domain/questionnaire';
import { GqlResultReferenceRange } from '@when-fertility/shared/gql/graphql';

export const determineAMHReferenceRange = (age: number, result: number): [TestKitResultReferenceRange, TestKitResultReferenceRangeValues] => {
  const ageGroup = getAgeGroup(age);
  const range = AMH_REFERENCE_RANGES[ageGroup];

  if (!range) {
    throw new Error(`Age range not found for AMH test ${age}`);
  }

  return getRange(result, range);
};

export const determineAMHReferenceRangeForTestBarPage = (
  ageGroup: TestKitResultAgeGroup,
  result: number
): [TestKitResultReferenceRange, TestKitResultReferenceRangeValues] => {
  // const ageGroup = getAgeGroup(age);
  const range = AMH_REFERENCE_RANGES[ageGroup];
  return getRange(result, range);
};

const getRange = (
  result: number,
  range: { PERCENTILE_2_5: number; LOW: number; HIGH: number }
): [TestKitResultReferenceRange, TestKitResultReferenceRangeValues] => {
  if (result < range.PERCENTILE_2_5) {
    return ['PERCENTILE_2_5', range];
  } else if (result >= range.PERCENTILE_2_5 && result < range.LOW) {
    return ['LOW', range];
  } else if (result >= range.LOW && result <= range.HIGH) {
    return ['IN_RANGE', range];
  } else if (result > range.HIGH) {
    return ['HIGH', range];
  }
  throw new Error(`Not able to define range reference`);
};

export const getAgeGroup = function (age: number): TestKitResultAgeGroup {
  if (age >= 25 && age <= 29) {
    return '25-29';
  } else if (age >= 30 && age <= 34) {
    return '30-34';
  } else if (age >= 35 && age <= 39) {
    return '35-39';
  } else if (age >= 40 && age <= 44) {
    return '40-44';
  } else if (age >= 45 && age <= 50) {
    return '45-50';
  } else {
    throw new Error(`Age range not found for AMH test ${age}`);
  }
};

export const buildResultReportNotes = (
  profileDetails: QuestionnaireDetail[] | undefined,
  age: number,
  referenceRange: TestKitResultReferenceRange
): string[] => {
  switch (referenceRange) {
    case 'PERCENTILE_2_5':
    case 'LOW':
      return lowReportNotes(profileDetails, age, referenceRange);
    case 'IN_RANGE':
      return inRangeReportNotes(age);
    case 'HIGH':
      return highReportNotes(age);
    default:
      return [];
  }
};

export const getDecimalPlace = (ageGroup: string): number => {
  let decimalPlace: number;
  if (ageGroup == '40-44' || ageGroup == '45-50') {
    decimalPlace = 3;
  } else {
    decimalPlace = 2;
  }
  return decimalPlace;
};

export const isLowAMH = (testKit: TestKitResult): boolean => {
  return testKit?.report?.referenceRange === 'PERCENTILE_2_5' || testKit?.report?.referenceRange === 'LOW';
};

const lowReportNotes = (profileDetails: QuestionnaireDetail[] | undefined, age: number, referenceRange: TestKitResultReferenceRange): string[] => {
  const notes: string[] = [];
  if (profileDetails) {
    const contraceptionType = profileDetails.find((detail) => detail.key === 'CONTRACEPTION_TYPE');
    const contraceptionPill = profileDetails.find((detail) => detail.key === 'CONTRACEPTION_PILL');
    const iudType = profileDetails.find((detail) => detail.key === 'CONTRACEPTION_IUD');
    const recentHormonalContraception = profileDetails.find((detail) => detail.key === 'RECENT_HORMONAL_CONTRACEPTION');

    if (
      contraceptionPill?.value === 'regular' ||
      contraceptionPill?.value === 'minipill' ||
      contraceptionType?.value === 'injection' ||
      contraceptionType?.value === 'implant' ||
      contraceptionType?.value === 'patch' ||
      contraceptionType?.value === 'ring' ||
      iudType?.value === 'hormonal'
    ) {
      notes.push(REPORT_NOTES.low_hormonal_contraception);
      notes.push(REPORT_NOTES.low_only_applies_no_hormone);
    } else if (
      recentHormonalContraception?.value === 'regular_pill' ||
      recentHormonalContraception?.value === 'minipill' ||
      recentHormonalContraception?.value === 'hormonal_iud' ||
      recentHormonalContraception?.value === 'injection' ||
      recentHormonalContraception?.value === 'implant' ||
      recentHormonalContraception?.value === 'patch' ||
      recentHormonalContraception?.value === 'ring'
    ) {
      notes.push(REPORT_NOTES.low_recent_hormonal_contraception);
      notes.push(REPORT_NOTES.low_only_applies_no_recent_hormone);
    }
  }
  notes.push(REPORT_NOTES.low_lower_eggs_than_expected);
  notes.push(REPORT_NOTES.low_shorter_reproductive_window);
  notes.push(REPORT_NOTES.low_ivf_fewer_eggs);

  const eggQuality = REPORT_NOTES.egg_quality(age);
  if (eggQuality) {
    notes.push(eggQuality);
  }

  if (referenceRange === 'PERCENTILE_2_5') {
    notes.push(REPORT_NOTES.very_low_talk_to_a_doctor);
  } else {
    notes.push(REPORT_NOTES.low_doesnt_predict_fertility);
  }

  return notes;
};

const inRangeReportNotes = (age: number): string[] => {
  const notes: string[] = [];

  notes.push(REPORT_NOTES.in_range_expected);
  notes.push(REPORT_NOTES.in_range_ivf_expected_eggs);

  const eggQuality = REPORT_NOTES.egg_quality(age);
  if (eggQuality) {
    notes.push(eggQuality);
    notes.push(REPORT_NOTES.family_planning_talk_to_a_doctor);
  }

  return notes;
};

const highReportNotes = (age: number): string[] => {
  const notes: string[] = [];

  notes.push(REPORT_NOTES.high_more_eggs_expected);
  notes.push(REPORT_NOTES.high_ivf_more_eggs);

  const eggQuality = REPORT_NOTES.egg_quality(age);
  if (eggQuality) {
    notes.push(eggQuality);
    notes.push(REPORT_NOTES.family_planning_talk_to_a_doctor);
  }

  notes.push(REPORT_NOTES.high_polycystic);

  return notes;
};

export const REFERENCE_RANGE_MAP: Record<GqlResultReferenceRange, string> = {
  PERCENTILE_2_5: 'Low',
  LOW: 'Low',
  IN_RANGE: 'In-range',
  HIGH: 'High',
};

export function convertAmhPmolToNg(pmolValue: number): number {
  // 7.14 pmol/L AMH = 1 pg/ml
  // based on information from our chief medical advisors.
  const PG_TO_ML_FACTOR = 7.14;
  const ngMlValue = pmolValue / PG_TO_ML_FACTOR;
  return ngMlValue;
}
