import { Button, Icon } from '@when-fertility/shared/domain/common';
import { GqlAddress, GqlAddressInput, useSettingsSummaryItemUpdateUserMutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import React, { useEffect, useState, useMemo } from 'react';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common/components';
import { PlacesAutocompleteComponent } from '../../auth/components/places-autocomplete.component';
type SettingsItem = 'firstName' | 'lastName' | 'email' | 'password' | 'phone' | 'address';

type Props = {
  settingsItem: SettingsItem;
  defaultValue?: GqlAddress | null;
  showDisclaimer?: boolean;
  onSuccess?: () => void;
};

const settingsLabelMap: Record<SettingsItem, string> = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  password: 'Password',
  phone: 'Phone',
  address: 'Address',
};

const formatAddress = ({ address1, address2, city, province, country, zip }: GqlAddress) => {
  const firstPart = [address1, address2].filter((item) => item && item.length > 0).join(' ');
  const addressArray = [firstPart ? `${firstPart}, ` : null, `${city}, ${province},`, zip, country];
  console.log(addressArray.filter((item) => item && item.length > 0));
  return addressArray.filter((item) => item && item.length > 0).join(' ');
};

export const SettingsSummaryAddressItem = ({ settingsItem, defaultValue, showDisclaimer = false, onSuccess }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formError, setFormError] = useState('');
  const [formValue, setFormValue] = useState<GqlAddress>({});

  const handleClickEdit = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!defaultValue || Object.keys(formValue).length !== 0) {
      return;
    }
    setFormValue(defaultValue);
  }, [defaultValue]);

  const [updateCurrentUser, { loading: isLoading }] = useSettingsSummaryItemUpdateUserMutation({
    variables: {
      input: {
        ...(settingsItem === 'address' && {
          address: {
            address1: formValue?.address1,
            address2: formValue?.address2,
            city: formValue?.city,
            province: formValue?.province,
            province_code: formValue?.province_code,
            zip: formValue?.zip,
            country: formValue?.country,
          },
        }),
      },
    },
    onCompleted: async () => {
      setIsModalOpen(false);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError: async (error) => {
      setFormError(error.message);
    },
  });

  const handleSave = async (event: any) => {
    event.preventDefault();
    setFormError('');

    await updateCurrentUser();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="md:hidden flex justify-between items-center border-b border-silver-100 last:border-none p-4">
        <div className="w-1/2">
          <div className="text-charcoal-60">{settingsLabelMap[settingsItem]}</div>
          <div className="mt-1 text-charcoal-100">{formValue && formatAddress(formValue)}</div>
        </div>
        <div>
          <Button variant="secondary" className="flex items-center" onClick={() => handleClickEdit()}>
            <Icon icon="pencil" height={14} className="mr-2" />
            Edit
          </Button>
        </div>
      </div>
      <div className="hidden md:flex p-6 border-b border-silver-100 justify-between items-center last:border-none">
        <div className="w-1/4 text-charcoal-60">{settingsLabelMap[settingsItem]}</div>
        <div className="w-3/4 flex justify-between items-center ml-12 text-charcoal-100">
          {formValue && formatAddress(formValue)}
          <Button className="flex items-center" variant="secondary" onClick={() => handleClickEdit()}>
            <Icon icon="pencil" height={14} className="mr-2" />
            Edit
          </Button>
        </div>
      </div>

      <FullScreenModalComponent isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="mt-6">
          <div className="w-full md:w-96">
            {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
            <div className="text-md mb-4">Update your {settingsLabelMap[settingsItem]}</div>
            <div className="text-md mb-1 text-charcoal-60">Current {settingsLabelMap[settingsItem]}</div>
            <div className="text-md mb-4">{defaultValue && formatAddress(defaultValue)}</div>

            <div className="text-md mb-2 text-charcoal-60">New {settingsLabelMap[settingsItem]}</div>
            <div className="h-full flex flex-col justify-between">
              <div className="md:h-24">
                <PlacesAutocompleteComponent setFormValue={(value: GqlAddressInput) => setFormValue(value)} />{' '}
              </div>
            </div>
          </div>
          {showDisclaimer && (
            <div className="w-full md:w-96 md:mt-0 mt-12 text-xs text-center">You’ll have to log in again after saving these changes</div>
          )}
          <div className="flex flex-col w-full mt-12">
            <Button onClick={handleSave} isDisabled={isLoading} className="">
              Save
            </Button>
            <Button onClick={handleCancel} className="mt-2" variant="secondary">
              Cancel
            </Button>
          </div>
        </div>
      </FullScreenModalComponent>
    </>
  );
};

SettingsSummaryAddressItem.mutation = gql`
  mutation SettingsSummaryItemUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
