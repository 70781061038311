import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { Page } from '@when-fertility/shared/domain/common';
import { useGetResultsPageQuery } from '@when-fertility/shared/gql/graphql';
import { ResultListItem } from './components/results-list-item-component';

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <Page title="Reports" headerClassName="pb-8" className="bg-white md:bg-silver-40" titleVariant="bright">
    {children}
  </Page>
);

export const ResultsPage = () => {
  const { data, loading, error } = useGetResultsPageQuery({});
  const testKits = useMemo(() => data?.testKits.testKits, [data]);

  if (loading) return <PageContainer>Loading....</PageContainer>;
  if (error) {
    return <PageContainer>{`Error! ${error}`}</PageContainer>;
  }
  return (
    <PageContainer>
      <div className="w-full space-y-8">
        <div className="mt-6 p-3">
          <div className="text-xs font-normal text-left">
            <div className="hidden sm:flex gap-2 bg-silver-100 px-10 py-4 text-charcoal-100 rounded-t-lg">
              <div className="w-1/3 text-base">Product</div>
              <div className="w-1/3 text-base">Sample Collected</div>
              <div className="w-1/3 text-base"></div>
            </div>
            {!testKits?.length && (
              <div className="flex sm:bg-white justify-center px-10 py-4 text-charcoal-100 w-full italic rounded-b-lg">No reports</div>
            )}
            {testKits &&
              Boolean(testKits.length) &&
              testKits.map((testKit, index) => {
                return <ResultListItem testKit={testKit} key={index} />;
              })}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

ResultsPage.fragments = {
  testKits: gql`
    fragment ResultsPageTestKitFragment on TestKit {
      id
      userId
      activationCode
      dateSampleCollected
      status
      resultsStatus
      accessionNumber
      product {
        title
      }
      questionnaire {
        id
        userId
        testKitId
        details {
          key
          value
          values
          comment
        }
      }
    }
  `,
};

ResultsPage.query = {
  testKits: gql`
    ${ResultsPage.fragments.testKits}
    query GetResultsPage($input: TestKitInput) {
      testKits(input: $input) {
        testKits {
          ...ResultsPageTestKitFragment
        }
      }
    }
  `,
};
