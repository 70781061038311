import React, { useEffect, useMemo, useState } from 'react';
import { TestKitSummaryItem, TestKitSummaryStepStatus } from './test-kit-summary-item.component';
import { Button } from '@when-fertility/shared/domain/common';
import { ACTIVATE_ROUTES } from '../../activate';
import { QUIZ_ROUTES } from 'domain/quiz/quiz.routes';
import gql from 'graphql-tag';
import { GqlTestKitSummaryTestKitFragmentFragment, useTestKitSummaryGetTestKitsQuery } from '@when-fertility/shared/gql/graphql';
import { testKitService } from '../../test-kit';
import { CollectSampleButton, PrepareForResultsButton, ViewedResultButton, NextStepSection } from '.';
import { USER_ROUTES } from 'domain/user';
import { useCurrentUser } from '@when-fertility/shared/domain/user/current-user.hook';
import { Link } from 'react-router-dom';
import { questionnaireQuestionsService } from '@when-fertility/shared/domain/questionnaire-questions';
import { DownloadReportButton } from '@when-fertility/shared/domain/results';

export type TestKitSummaryStep = 'activate' | 'quiz' | 'collect' | 'prepare' | 'results' | 'next';
const STEP_ORDER: TestKitSummaryStep[] = ['activate', 'quiz', 'collect', 'prepare', 'results', 'next'];

export const TestKitSummary = () => {
  const [expandedItem, setExpandedItem] = useState<TestKitSummaryStep | null>(null);
  const { loggedInUserId } = useCurrentUser();
  const { data: testKitData, loading } = useTestKitSummaryGetTestKitsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !loggedInUserId,
  });
  const latestTestKit = useMemo(
    () => testKitService.getLatestTestKit<GqlTestKitSummaryTestKitFragmentFragment>(testKitData?.testKits.testKits),
    [testKitData]
  );
  const latestTestKitResult = useMemo(() => {
    const testKit = testKitService.getLatestTestKit<GqlTestKitSummaryTestKitFragmentFragment>(testKitData?.testKits.testKits);
    return testKit?.results?.[0];
  }, [testKitData]);
  const isTestKitActivated = useMemo(() => testKitService.getIsTestKitActivated(latestTestKit?.status), [latestTestKit?.status]);
  const isSampleCollected = useMemo(() => Boolean(latestTestKit?.isSampleCollected), [latestTestKit]);
  const hasPreparedForResults = useMemo(() => Boolean(latestTestKit?.hasPreparedForResults), [latestTestKit]);
  const hasViewedResult = useMemo(() => Boolean(latestTestKit?.hasViewedResult), [latestTestKit]);
  const isProfileCompleted = useMemo(
    () => Boolean(questionnaireQuestionsService.getIsProfileComplete(latestTestKit?.questionnaire?.details || [])),
    [latestTestKit]
  );
  const canFillOutQuestionnaire = useMemo(
    () => (latestTestKit ? Boolean(!latestTestKit.results?.find((result) => result.report)) : false),
    [latestTestKit]
  );
  const isReceivedReleased = useMemo(() => latestTestKit?.resultsStatus === 'RECEIVED_RELEASED', [latestTestKit]);
  const isRecievedNotReleased = useMemo(() => latestTestKit?.resultsStatus === 'RECEIVED_NOT_RELEASED', [latestTestKit]);

  const itemStepMapping: Record<TestKitSummaryStep, TestKitSummaryStepStatus> = useMemo(() => {
    const getCurrentStep = () => {
      if (isTestKitActivated) {
        // These folks have viewed and completed
        if (hasViewedResult) {
          return 'next';
        }

        if (canFillOutQuestionnaire && !isProfileCompleted) {
          return 'quiz';
        }

        if (isReceivedReleased) {
          // Need to show results as those folks have seen them already
          return 'results';
        }

        // Pending questionnaire, regardless of result state
        if (!isProfileCompleted && canFillOutQuestionnaire) {
          return 'quiz';
        }

        if (isSampleCollected && !hasPreparedForResults) {
          return 'prepare';
        }

        if (!hasPreparedForResults) {
          return 'collect';
        }

        // Their profile is complete and they're prepared, but haven't seen results yet
        if (isRecievedNotReleased) {
          return 'results';
        }

        if (isProfileCompleted) {
          return 'results';
        }
      }
      return 'activate';
    };

    const currentStep = getCurrentStep();

    const mapping: Record<TestKitSummaryStep, TestKitSummaryStepStatus> = {
      activate: 'futureStep',
      quiz: 'futureStep',
      collect: 'futureStep',
      prepare: 'futureStep',
      results: 'futureStep',
      next: 'futureStep',
    };
    let hasFoundCurrent = false;
    STEP_ORDER.forEach((step) => {
      if (!hasFoundCurrent && currentStep === step) {
        hasFoundCurrent = true;
        mapping[step] = 'currentStep';
        return;
      }

      if (!hasFoundCurrent) {
        mapping[step] = 'completed';
      }
    });
    return mapping;
  }, [isTestKitActivated, isSampleCollected, latestTestKit?.resultsStatus, isProfileCompleted, hasPreparedForResults, hasViewedResult]);

  useEffect(() => {
    const currentStep = STEP_ORDER.find((step) => itemStepMapping[step] === 'currentStep');
    if (!currentStep) {
      // if next are completed, expand next section
      if (itemStepMapping.next === 'completed') {
        setExpandedItem('next');
        return;
      }
      return;
    }
    setExpandedItem(currentStep);
  }, [itemStepMapping]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white rounded-2xl border border-silver-100">
      <TestKitSummaryItem
        title="Activate your kit"
        stepKey="activate"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
        stepStatus={itemStepMapping['activate']}
      >
        {isTestKitActivated && (
          <div title={`Kit ${latestTestKit.accessionNumber} is activated`}>
            Great! Your kit has been activated.
            {process.env.SHOW_ACTIVATION_CODES === 'true' && (
              <div className="mt-4 text-charcoal-60 text-xs">Activation code: {latestTestKit.activationCode}</div>
            )}
          </div>
        )}
        {!isTestKitActivated && (
          <>
            <div className="mb-6">
              You&apos;ll need to activate your kit using the code on your activation card before you can collect your sample.
            </div>
            <Button isLink href={ACTIVATE_ROUTES.root}>
              Activate kit
            </Button>
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['quiz']}
        title="Complete your questionnaire"
        stepKey="quiz"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {isProfileCompleted && canFillOutQuestionnaire && (
          <div>
            Your questionnaire is complete! You can update it any time before we receive your results
            <Link to={`${USER_ROUTES.questionnaire}?testKitId=${latestTestKit?.id}`} className="underline ml-1">
              here.
            </Link>
          </div>
        )}
        {!canFillOutQuestionnaire && <div>Your questionnaire is complete!</div>}
        {canFillOutQuestionnaire && !isProfileCompleted && (
          <>
            <div className="mb-6">
              Our medical team uses this information to make sure we deliver personalised insights to you about your egg count. You’ll need to
              complete your questionnaire before we can make your report available.
            </div>
            <Button isLink className="text-xs" href={`${QUIZ_ROUTES.root}?testKitId=${latestTestKit?.id}`}>
              Complete questionnaire
            </Button>
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['collect']}
        title="Collect your sample"
        stepKey="collect"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {isSampleCollected && <div>You&apos;ve collected your sample. Please return your kit promptly. </div>}
        {isProfileCompleted && !isSampleCollected && (
          <>
            <div className="mb-6">Thanks for completing your questionnaire, now it's time to collect your sample.</div>
            <div className="mb-3">
              Our Senior Fertility Nurse will show you how to collect your sample in the video below. Along with the instructions in your kit, this
              will make sure you collect your sample successfully.
            </div>
            <div className={'flex items-center mb-6'}>
              <div className={'w-full min-w-32'}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/954657002?h=858d170620&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '8px' }}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <CollectSampleButton testKit={latestTestKit} isDisabled={!isTestKitActivated} />
          </>
        )}
        {!isTestKitActivated && <>Please activate your kit before collecting your sample.</>}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['prepare']}
        title="Prepare for your results"
        stepKey="prepare"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {hasPreparedForResults && <div>You&apos;ve finished this step. </div>}
        {!hasPreparedForResults && (
          <>
            <div className="mb-3">
              While we process your sample and prepare your report, WHEN’s Chief Medical Advisors would like to share some important information with
              you about AMH and your overall fertility picture.
            </div>
            <div className={'flex items-center h-40 sm:h-72 lg:h-80 xl:h-96'}>
              <div className={'w-full min-w-32'}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/954671874?h=429e6a6472&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '20px' }}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className={'flex items-center h-40 sm:h-72 lg:h-80 xl:h-96'}>
              <div className={'w-full min-w-32'}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/954672047?h=8a2ab2572f&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '20px' }}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <PrepareForResultsButton testKit={latestTestKit} isDisabled={!isTestKitActivated} />
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['results']}
        title="Get your result"
        stepKey="results"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {!latestTestKit && <div className="mb-6">Once you&apos;ve activated and sent your test kit, we can start processing your results.</div>}
        {isRecievedNotReleased && (
          <div className="mb-6">We've received your results and are reviewing them for release. We'll let you know as soon as they're ready!</div>
        )}
        {!isReceivedReleased && !isRecievedNotReleased && (
          <div className="mb-6">We&apos;re waiting on your results. We&apos;ll let you know as soon as they&apos;re ready!</div>
        )}
        {isReceivedReleased && (isProfileCompleted || !canFillOutQuestionnaire) && (
          <>
            <div className="mb-6">Your result is ready for review.</div>
            <DownloadReportButton size="regular" testKitId={latestTestKit?.id} reportType={'EGG_COUNT_REPORT'} />
            <div className="mb-3" />
            <div className="mb-3" />
            <ViewedResultButton testKit={latestTestKit} isDisabled={hasViewedResult} />
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['next']}
        title="Your next steps"
        stepKey="next"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        <NextStepSection result={latestTestKitResult} testKit={latestTestKit} />
      </TestKitSummaryItem>
    </div>
  );
};

TestKitSummary.fragments = {
  testKits: gql`
    fragment TestKitSummaryTestKitFragment on TestKit {
      id
      status
      isSampleCollected
      dateSampleCollected
      resultsStatus
      hasPreparedForResults
      hasViewedResult
      dateResultsReceived
      apptBookedAnswer
      accessionNumber
      activationCode
      results {
        abbreviation
        units
        name
        result
        report {
          ageGroup
          notes
          referenceRange
          range {
            PERCENTILE_2_5
            LOW
            HIGH
          }
        }
      }
      questionnaire {
        id
        userId
        testKitId
        details {
          key
          value
          values
          comment
        }
      }
    }
  `,
};

TestKitSummary.query = {
  testKits: gql`
    ${TestKitSummary.fragments.testKits}

    query TestKitSummaryGetTestKits($testKitInput: TestKitInput) {
      testKits(input: $testKitInput) {
        testKits {
          ...TestKitSummaryTestKitFragment
        }
      }
    }
  `,
};
