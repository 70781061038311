import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { User } from 'firebase/auth';
import { authService } from './auth.service';
import { localStorageService } from '@when-fertility/shared/domain/common';
import { clearLocalApolloCache } from 'domain/common';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '../dashboard';

export type AuthContextType = {
  firebaseUser: User | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  logout: () => Promise<void>;
  logInAndRedirect: ({ email, password, fallbackPath }: { email: string; password: string; fallbackPath?: string }) => Promise<void>;
  refreshAuth: () => Promise<void>;
  token: string | null;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [token, setToken] = useState<AuthContextType['token']>('');
  const [firebaseUser, setFirebaseUser] = useState<AuthContextType['firebaseUser']>(null);
  const navigate = useNavigate();

  useEffect(() => {
    authService.onAuthStateChanged(async (firebaseUser) => {
      setFirebaseUser(firebaseUser);

      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken());
      } else {
        setToken(null);
      }
      setIsAuthenticating(false);
    });

    authService.onIdTokenChanged(async () => {
      // If the token refreshes, we will store the new token in state.
      if (firebaseUser) {
        setToken(await firebaseUser.getIdToken());
      }
    });
  }, []);

  useEffect(() => {
    // this condition here is to not clean the token out when the AuthProvider
    // first initialised, otherwise the token is cleaned every time we refresh the page
    if (token === '') {
      return;
    }
    if (token) {
      localStorageService.setItem('firebase-token', token);
      if (firebaseUser) {
        localStorageService.setItem('firebase-uid', firebaseUser.uid);
      }
    } else {
      localStorageService.removeItem('firebase-token');
      localStorageService.removeItem('firebase-uid');
    }
  }, [token]);

  const login = async (email: string, password: string) => {
    const firebaseUser = await authService.signInWithEmailAndPassword(email, password);

    setFirebaseUser(firebaseUser);
    setToken(await firebaseUser.getIdToken());
  };

  const logout = async () => {
    await authService.signOut();
    clearLocalApolloCache();
  };

  const logInAndRedirect = async ({
    email,
    password,
    fallbackPath = DASHBOARD_ROUTES.root,
  }: {
    email: string;
    password: string;
    fallbackPath?: string;
  }) => {
    const redirectPath = localStorageService.getItem('redirect');
    await login(email, password);
    localStorageService.setItem('redirect', ''); // Clear this for next time they log in

    if (redirectPath) {
      await navigate(redirectPath);
      return;
    }

    // TODO: a check here if they are a nurse take em to nurse dashboard

    await navigate(fallbackPath);
  };

  const refreshAuth = async () => {
    const firebaseUser = await authService.reloadFirebaseUser();

    setFirebaseUser(firebaseUser);
    if (firebaseUser) {
      setToken(await firebaseUser.getIdToken());
    }
  };

  const value = useMemo(
    () => ({
      firebaseUser,
      isAuthenticated: Boolean(firebaseUser) && Boolean(token),
      isAuthenticating,
      logout,
      logInAndRedirect,
      refreshAuth,
      token,
    }),
    [isAuthenticating, firebaseUser, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('This component must be used within a <AuthProvider> component.');
  }

  return context;
};
