import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { localStorageService } from '@when-fertility/shared/domain/common';
import { onError } from '@apollo/client/link/error';
import { authService } from 'domain/auth';

const httpLink = new HttpLink({
  uri: process.env.API_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorageService.getItem('firebase-token');
  const uid = localStorageService.getItem('firebase-uid');

  return {
    headers: {
      ...headers,
      authorization: token || '',
      uid: uid || '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
  if (graphQLErrors) {
    const authFailedError = graphQLErrors.find((error) => error.message.toLowerCase().includes('authentication failed'));
    const userNotFoundError = graphQLErrors.find((error) => error.message.toLowerCase().includes('user not found'));

    if (authFailedError) {
      authService.reloadFirebaseUser().then((firebaseUser) => {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: firebaseUser?.getIdToken(),
          },
        });
        // Retry the request, returning the new observable
        return forward(operation);
      });
    } else if (userNotFoundError) {
      // Redirect to login page
      window.location.href = '/login';
    }
  }
});

export const apolloCache = new InMemoryCache();

export const apolloService = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: apolloCache,
});

export const clearLocalApolloCache = async () => {
  await apolloService.clearStore();
};
