import React, { useState } from 'react';
import { MasterclassVideo, NextStepItem } from '.';
import { GqlResult, GqlResultReferenceRange, GqlTestKitSummaryTestKitFragmentFragment } from '@when-fertility/shared/gql/graphql';
import { FullScreenModalComponent } from '@when-fertility/shared/domain/common';
import { REFERENCE_RANGE_MAP } from '@when-fertility/shared/domain/test-kit/test-kit.utils';
import { DownloadReportButton } from '@when-fertility/shared/domain/results';

interface Props {
  result: GqlResult | undefined;
  testKit: GqlTestKitSummaryTestKitFragmentFragment | undefined;
}

export const NextStepSection = ({ result, testKit }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const referenceRangeName: string = REFERENCE_RANGE_MAP[result?.report?.referenceRange];
  const ageGroup: string | undefined = result?.report?.ageGroup;
  const getRetestDuration = (refRange: GqlResultReferenceRange | undefined, ageGroup: string) => {
    if (refRange === 'LOW' || refRange === 'PERCENTILE_2_5') {
      return '12 months';
    } else if (refRange === 'IN_RANGE' || refRange === 'HIGH') {
      return ageGroup === '25-29' || ageGroup === '30-34' ? '24 months' : '12 months';
    }
    return '';
  };
  const onCloseModal = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  return (
    <div className="overflow-visible">
      <NextStepItem indexValue="01" header="Hear more about your result from our Chief Medical Advisors">
        <MasterclassVideo referenceRange={result?.report?.referenceRange} />
      </NextStepItem>
      <NextStepItem indexValue="02" header="Monitor and track your AMH">
        <div className="mt-8 lg:mt-0 ml-20 md:ml-32 lg:w-72 lg:ml-24">
          <p className="text-sm">It's important to continue to track your AMH so you're across how it's moving over time.</p>
          <p className="text-sm mt-4">
            Since your result is currently {referenceRangeName?.toLowerCase()}, we recommend you retest in{' '}
            {getRetestDuration(result?.report?.referenceRange, ageGroup)} time. We’ll send you a reminder just before then.{' '}
          </p>
        </div>
      </NextStepItem>
      <NextStepItem indexValue="03" header="Download your Health Practitioner Report">
        <div className="mt-4 lg:mt-0 ml-20 md:ml-32 lg:w-72 lg:ml-24 items-center">
          {testKit?.resultsStatus === 'RECEIVED_RELEASED' ? (
            <DownloadReportButton size="regular" testKitId={testKit?.id} reportType={'HEALTH_PRACTITIONER_REPORT'} />
          ) : (
            ''
          )}
          <p className="mt-6 text-sm">
            Take this report to your next appointment with your Health Practitioner so they're across your results and you can continue your fertility
            conversation.{' '}
          </p>
        </div>
      </NextStepItem>
      <FullScreenModalComponent isOpen={isModalOpen} setIsOpen={onCloseModal}>
        <iframe
          className="w-full md:w-5/6"
          style={{ height: '1000px' }}
          scrolling="no"
          src="https://scheduler.zoom.us/when-fertility-appointments/tele-education-appointment?embed=true"
        ></iframe>
      </FullScreenModalComponent>
    </div>
  );
};
