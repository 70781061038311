import React, { FormEvent, useEffect, useState } from 'react';
import { useAuth } from '../auth.provider';
import { Link } from 'react-router-dom';
import { Button, Icon, Input, Page } from '@when-fertility/shared/domain/common';
import { AUTH_ROUTES } from '..';
import { captureException } from '@sentry/react';

export const LoginPage = () => {
  const { logInAndRedirect, logout } = useAuth();
  const [email, setEmail] = useState(process.env.DEFAULT_EMAIL || '');
  const [password, setPassword] = useState(process.env.DEFAULT_PASSWORD || '');
  const [isSaving, setIsSaving] = useState(false);
  const [formError, setFormError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    (async () => {
      // Logging the user out of any sessions they may be in if they come directly to the login page.
      await logout();
    })();
  }, []);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setFormError('');
      setIsSaving(true);
      await logInAndRedirect({ email, password });
    } catch (error: any) {
      if (!['auth/wrong-password', 'auth/invalid-credential'].includes(error?.code)) {
        captureException(error); // TODO: identify password incorrect error, ignore it and track unknown error.
      }
      setFormError('Email or password are incorrect.');
      setIsSaving(false);
    }
  };

  return (
    <Page isCentred variant="medium" className="bg-blob-outline bg-center bg-no-repeat bg-[length:130%] md:bg-[length:auto]">
      <div className="w-80 sm:w-100 px-6">
        <h2 className="mt-4 text-3xl text-center">Log in</h2>
        <form className="mt-8" onSubmit={handleFormSubmit}>
          <div className="mb-3">
            <Input
              type="email"
              onChange={({ target }) => setEmail((target as HTMLInputElement).value)}
              value={email}
              isRequired
              isDisabled={isSaving}
              placeholder="Email address"
            />
          </div>
          <div className="mb-3 relative">
            <Input
              type={showPassword ? 'text' : 'password'}
              onChange={({ target }) => setPassword((target as HTMLInputElement).value)}
              value={password}
              isRequired
              isDisabled={isSaving}
              placeholder="Password"
            />
            <button
              type="button"
              onClick={handleTogglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
            >
              {showPassword ? (
                <Icon icon="cross" className="text-gray-400" height={15} width={24} />
              ) : (
                <Icon icon="eye-open" className="text-gray-400" height={20} width={30} />
              )}
            </button>
          </div>
          {formError && <div className="text-red-600 text-center text-sm mb-4 mt-2">{formError}</div>}
          <Button type="submit" isDisabled={isSaving} className="w-full mb-8">
            Log in
          </Button>
          <div className="flex flex-col items-center">
            <div className="mb-2 text-xs">
              <Link to={AUTH_ROUTES.forgotPassword} className="underline">
                Forgot your password?
              </Link>
            </div>
            <div className="mb-2 text-xs text-charcoal-100">
              Don&apos;t have an account?
              <Link to={AUTH_ROUTES.register} className="pl-1 underline">
                Sign up here
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Page>
  );
};
