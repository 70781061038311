import React, { useMemo } from 'react';
import { Page, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { QuestionnaireSummary } from '@when-fertility/shared/domain/questionnaire/questionnaire-summary.component';
import { QuestionnaireProvider } from '@when-fertility/shared/domain/questionnaire/questionnaire.provider';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const QuestionnairePage = () => {
  const [searchParams] = useSearchParams();
  const testKitId = useMemo(() => searchParams.get(URL_PARAMETERS.testKitId), [searchParams]);

  if (!testKitId) {
    return <div className="text-2xl mt-8 text-center">No test kit found</div>;
  }

  return (
    <Page title="Questionnaire" className="bg-white md:bg-silver-40" titleVariant="bright">
      <QuestionnaireProvider allowNextOnChange={false} testKitId={testKitId}>
        <QuestionnaireSummary />
      </QuestionnaireProvider>
    </Page>
  );
};
