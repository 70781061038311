import React, { useEffect, useMemo, useState } from 'react';
import { QuestionnaireQuestion } from '@when-fertility/shared/user-profile';
import cn from 'classnames';
import { useQuestionnaire } from '@when-fertility/shared/domain/questionnaire/questionnaire.provider';

type Props = {
  options: QuestionnaireQuestion['options'];
};

export const QuizMultiSelect = ({ options }: Props) => {
  const { currentQuestion, formData, updateField } = useQuestionnaire();
  const currentQuestionData = useMemo(() => formData[currentQuestion.key], [formData, currentQuestion]);
  const labels = Object.values(options || {});
  const values = Object.keys(options || {});
  const [selectedValues, setSelectedValues] = useState<string[]>(currentQuestionData?.values || []);
  const [comment, setComment] = useState<string>(currentQuestionData?.comment || '');

  const handleClick = ({ value }: { value: string }) => {
    const isSelected = selectedValues.includes(value);
    // Toggle the option's selection status
    if (isSelected) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  useEffect(() => {
    updateField({ id: currentQuestion.key, value: { values: selectedValues, comment: comment } });
  }, [currentQuestion, selectedValues, comment]);

  return (
    <>
      {labels.map((label, index) => {
        return (
          <div
            key={index}
            className={cn(
              'min-h-quiz-multi-select flex items-center bg-silver-80 hover:bg-silver-100 mt-2 rounded-full px-4 py-2 hover:cursor-pointer border-3 leading-5',
              {
                'border border-charcoal-100': selectedValues.includes(values[index]),
              }
            )}
          >
            <input
              type="checkbox"
              className={cn('hidden')}
              id={`roundedCheckbox-${index}`}
              value={values[index]}
              checked={selectedValues.includes(values[index])}
              key={values[index]}
              onChange={() => handleClick({ value: values[index] })}
            />
            <label htmlFor={`roundedCheckbox-${index}`} className={'w-full flex flex-row items-center cursor-pointer'}>
              <svg
                className={cn('w-4 h-4 rounded-full border border-charcoal-100 mr-3', {
                  'bg-charcoal-20 text-charcoal-100': selectedValues.includes(values[index]),
                  'bg-transparent text-transparent': !selectedValues.includes(values[index]),
                })}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              {label}
            </label>
          </div>
        );
      })}
      <div className="relative flex-row items-center">
        <div className="mt-4 mb-4 w-ful">Is there anything else you think we need to know?</div>
        <input
          className="rounded-md w-full p-4 resize-none border border-silver-100"
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          placeholder={'Write notes here...'}
        />
      </div>
    </>
  );
};
