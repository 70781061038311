import React from 'react';
import { Page } from '../page/page.component';

type Props = {
  isPage?: boolean;
  message?: string;
};

export const ErrorComponent = ({ isPage = false, message = null }: Props) => {
  return (
    <>
      {isPage && (
        <Page isCentred>
          <div className="text-2xl">Whoops!</div>
          <div className="mt-2">{message || 'Something went wrong there. Please try again.'}</div>
        </Page>
      )}
      {!isPage && (
        <>
          <div className="text-2xl">Whoops!</div>
          <div className="mt-2">{message || 'Something went wrong there. Please try again.'}</div>
        </>
      )}
    </>
  );
};
