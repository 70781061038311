import React from 'react';
import { Button } from '@when-fertility/shared/domain/common';
import { GqlDownloadReportQuery, GqlReportType, useDownloadReportLazyQuery } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';

type Props = {
  testKitId: string;
  isDisabled?: boolean;
  reportType: GqlReportType;
  setIsModalOpen?: (isOpen: boolean) => void;
  size?: string;
};

export const DownloadReportButton = ({ testKitId, isDisabled = false, reportType, size = 'small' }: Props) => {
  const [downloadReport, { data, loading, error }] = useDownloadReportLazyQuery({});
  const handleDownload = async () => {
    if (!testKitId) {
      return;
    }
    await downloadReport({
      variables: {
        input: {
          testKitId: testKitId,
          reportType: reportType,
        },
      },
      onCompleted: ({ getReportLink }: GqlDownloadReportQuery) => {
        const { preSignedLink } = getReportLink;
        window.open(preSignedLink, '_blank');
      },
    });
  };

  if (!testKitId) {
    return null;
  }

  const buttonText = () => {
    if (reportType === 'EGG_COUNT_REPORT') {
      return size !== 'small' ? 'Download Egg Count Report' : 'Download ECR';
    }

    if (reportType === 'HEALTH_PRACTITIONER_REPORT') {
      return size !== 'small' ? 'Download Health Practitioner Report' : 'Download HPR';
    }

    return 'View Notes';
  };

  return (
    <>
      <Button className="text-white" size={size} isDisabled={isDisabled || loading} onClick={handleDownload}>
        {buttonText()}
      </Button>
    </>
  );
};

DownloadReportButton.query = gql`
  query downloadReport($input: ReportLinkInput!) {
    getReportLink(input: $input) {
      preSignedLink
    }
  }
`;
