import { KitQuestionnaireQuestions } from './questionnaire-questions.type';

/** Future considerations:
 * Ability to swap questions in/out per-test (but the quiz still exists in the same part of the flow)
 * Ability to enter in quizzes anywhere in the flow, and for all results to be shared wherever you're viewing the quiz
 * Conditional rendering of questions
 * Validation
 * Rendering questions on the questionnaire - for both single and multiple tests
 * Any overlap of questions between test types
 * How do we know when the questionnaire is 'complete'
 */

export const QUESTIONNAIRE_QUESTIONS: KitQuestionnaireQuestions[] = [
  {
    kit_type: 'OVARIAN_RESERVE',
    questions: [
      {
        key: 'TEST_RELATIONSHIP_STATUS',
        type: 'select',
        title: "What's your relationship status?",
        isRequiredToCompleteProfile: true,
        label: 'Relationship status',
        options: {
          single: "I'm single",
          has_partner: 'I have a partner',
        },
      },
      {
        key: 'TEST_PLANING',
        type: 'select',
        title: "Life takes planning - What's yours?",
        isRequiredToCompleteProfile: true,
        label: 'Planning',
        options: {
          trying: "I'm trying for kids",
          trying_next_year: "I'm thinking about trying in the next year",
          trying_another_year: "I want a kid, but won't get started for at least another year",
          dont_want_kids: "I don't want biological kids - I'm just curious to learn",
          completed_family: "I've completed my family",
          not_sure: "I'm not sure yet!",
        },
      },
      {
        key: 'HOW_LONG_TRYING_PREGNANT',
        type: 'text',
        showIfSelected: { TEST_PLANING: ['trying'] },
        title: 'How long have you been trying to become pregnant?',
        label: 'Months trying for pregnancy',
        fieldLabel: 'months',
        placeholder: '0',
      },
      {
        key: 'CONTRACEPTION',
        type: 'select',
        label: 'Birth control',
        isRequiredToCompleteProfile: true,
        title: 'Are you currently using contraception?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'CONTRACEPTION_TYPE',
        type: 'select',
        showIfSelected: { CONTRACEPTION: ['yes'] },
        label: 'Birth control type',
        title: 'What type of contraception are you using?',
        showCommentIfSelected: 'other',
        options: {
          pill: 'Pill',
          iud: 'IUD',
          injection: 'Injection',
          implant: 'Implant',
          patch: 'Patch',
          ring: 'Ring',
          barrier: 'Barrier (condom/diaphragm)',
          withdrawal: 'Withdrawal',
          abstinence: 'Abstinence',
          // other: 'Other',
        },
      },
      {
        key: 'CONTRACEPTION_PILL',
        type: 'select',
        showIfSelected: { CONTRACEPTION_TYPE: ['pill'] },
        title: 'What type of oral contraceptive pill do you take?',
        blurb:
          'The regular (combined) pill is the most common and contains estrogen and progesterone. The minipill is a less common form that contains progesterone only.',
        label: 'Contraception pill',
        options: {
          regular: 'Regular (Combined) Pill',
          minipill: 'Minipill',
        },
      },
      {
        key: 'CONTRACEPTION_IUD',
        type: 'select',
        showIfSelected: { CONTRACEPTION_TYPE: ['iud'] },
        label: 'Contraception IUD',
        title: 'What type of IUD do you have?',
        options: {
          hormonal: 'Hormonal IUD (E.g Mirena, Kyleena)',
          copper: 'Copper IUD (E.g Multiload/Copper T)',
        },
      },
      {
        key: 'RECENT_HORMONAL_CONTRACEPTION',
        type: 'select',
        showIfSelected: { CONTRACEPTION: ['no'], CONTRACEPTION_TYPE: ['barrier', 'withdrawal', 'abstinence'], CONTRACEPTION_IUD: ['copper'] },
        label: 'Recent Hormonal Contraception',
        title: 'Have you used any of the below types of hormonal contraception in the last 3 months? (if yes,tick the relevant type)',
        options: {
          regular_pill: 'Regular (combined) pill',
          minipill: 'Minipill',
          hormonal_iud: 'Hormonal IUD (E.g Mirena, Kyleena)',
          injection: 'Injection',
          implant: 'Implant',
          patch: 'Patch',
          ring: 'Ring',
          no: "No, I haven't used any of these in the last 3 months.",
        },
      },
      {
        key: 'PERIOD_PILL',
        type: 'select',
        showIfSelected: { CONTRACEPTION_PILL: ['regular'] },
        label: 'Menstruation',
        title: "What's your period like?",
        blurb: "Tell us what's most common for you and your period right now",
        options: {
          regular: 'I experience regular periods on the sugar (placebo) pill',
          none: "I don't get a period on the sugar (placebo) pills",
          spot: 'I spot a little during the sugar (placebo) pills',
          dont_bleed: "I don't bleed because I use the pill continuously",
        },
      },
      {
        key: 'PERIOD_REGULAR',
        type: 'select',
        showIfSelected: {
          CONTRACEPTION_PILL: ['minipill'],
          CONTRACEPTION_IUD: ['hormonal'],
          CONTRACEPTION_TYPE: ['injection', 'implant', 'patch', 'ring'],
          RECENT_HORMONAL_CONTRACEPTION: ['regular_pill', 'minipill', 'hormonal_iud', 'injection', 'implant', 'patch', 'ring', 'no'],
        },
        label: 'Menstruation',
        title: "What's your period like?",
        blurb: "Tell us what's most common for how often you get your period",
        options: {
          less_than_25_days: 'Less than 25 days between periods',
          between_26_35_days: '26-35 days between periods',
          more_than_35_days: 'More than 35 days between periods',
          dont_bleed: "I don't get a period",
          a_few_times: 'I only get a period a few times a year',
          irregular: 'I get a period, but its irregular',
        },
      },
      {
        key: 'PERIOD_FREQUENCY',
        type: 'text',
        showIfSelected: {
          PERIOD_REGULAR: ['irregular'],
        },
        label: 'Frequency',
        title: 'In the last 12 months, how often have you had a period?',
        fieldLabel: 'times',
        placeholder: '0',
      },
      {
        key: 'PROFILE_HISTORY',
        type: 'multi-select',
        isRequiredToCompleteProfile: true,
        label: 'History',
        title: 'Do you have a history of any of the below?',
        options: {
          pcos: 'PCOS',
          type_1_diabetes: 'Type 1 diabetes',
          type_2: 'Type 2 diabetes',
          thyroid_hyperthyroidism: 'Thyroid - Hyperthyroidism',
          thyroid_hypothyroidism: 'Thyroid - Hypothyroidism',
          thyroid_removed: 'Thyroid - Removed',
          endometriosis: 'Endometriosis',
          ovarian_surgery: 'Ovarian surgery',
          cancer: 'Cancer',
          not_sure: "I'm not sure",
          none_of_these: 'None of these',
        },
      },
      {
        key: 'SMOKING',
        type: 'select',
        isRequiredToCompleteProfile: true,
        label: 'Smoker',
        title: 'Do you smoke?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'SMOKING_NUMBER',
        type: 'text',
        showIfSelected: { SMOKING: ['yes'] },
        label: 'Smoking frequency',
        title: 'How many cigarettes do you smoke per day?',
        fieldLabel: 'cigarettes',
        placeholder: '0',
      },
      {
        key: 'E_CIGARETTE',
        type: 'select',
        isRequiredToCompleteProfile: true,
        label: 'E-cigarette',
        title: 'Do you use an e-cigarette(vape)?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'ALCOHOL',
        type: 'select',
        label: 'Alcohol',
        isRequiredToCompleteProfile: true,
        title: 'Do you drink alcohol?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'ALCOHOL_NUMBER',
        type: 'text',
        showIfSelected: { ALCOHOL: ['yes'] },
        label: 'Alcohol frequency',
        title: 'How many standard drinks per week?',
        fieldLabel: 'standard drinks',
        placeholder: '0',
      },
      {
        key: 'MEDICATION',
        type: 'select',
        isRequiredToCompleteProfile: true,
        label: 'Medications, supplements & vitamins',
        title: 'Are you on any medications, supplements or vitamins?',
        placeholder: 'List them here',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'MEDICATION_INFO',
        type: 'textarea',
        showIfSelected: { MEDICATION: ['yes'] },
        label: 'Medications, supplements & vitamins info',
        title: 'Can you give us details of any medications, supplements or vitamins you take?',
        placeholder: 'List them here',
      },
      {
        key: 'HEIGHT',
        type: 'text',
        label: 'Height',
        isRequiredToCompleteProfile: true,
        title: 'How tall are you?',
        placeholder: '0',
        fieldLabel: 'cm',
      },
      {
        key: 'WEIGHT',
        type: 'text',
        label: 'Weight',
        isRequiredToCompleteProfile: true,
        title: 'How much do you weigh?',
        placeholder: '0',
        fieldLabel: 'kg',
      },
      {
        key: 'PREGNANCY',
        type: 'select',
        label: 'Pregnancy',
        isRequiredToCompleteProfile: true,
        title: 'Have you ever been pregnant?',
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'ETHNICITY',
        type: 'select',
        label: 'Ethnicity',
        title: 'What is your racial/ethnic background?',
        isRequiredToCompleteProfile: true,
        options: {
          aboriginal_australian: 'Aboriginal(Australian)',
          aboriginal_other: 'Aboriginal(Other,eg Canadian )',
          african: 'African',
          african_american: 'African(American/Canadian)',
          asian_east: 'Asian - East (E.g Chinese, Japanese, Korean)',
          asian_south: 'Asian - South (E.g Indian, Pakistani, Sri Lankan)',
          asian_south_east: 'eg Malaysian, Filipino, Vietnamese',
          caribbean: 'Caribbean',
          latin_american: 'Latin American',
          middle_eastern: 'Middle Eastern',
          pacific_islander: 'Pacific Islander',
          torres_strait_islander: 'Torres Strait Islander',
          white: 'White (Australia, American, Canadian, European)',
          other: 'Other',
        },
      },
      {
        key: 'ADDITIONAL_NOTE',
        type: 'select',
        isRequiredToCompleteProfile: true,
        label: 'Additional notes',
        title: "Almost There! Before we complete your questionnaire, is there anything else that you'd like us to know?",
        options: {
          yes: 'Yes',
          no: 'No',
        },
      },
      {
        key: 'ADDITIONAL_INFO',
        type: 'textarea',
        showIfSelected: { ADDITIONAL_NOTE: ['yes'] },
        label: 'Additional Information',
        title: 'Additional Information',
        placeholder: 'Write them here',
      },
    ],
  },
];
