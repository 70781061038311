import { GqlQuestionnaireDetailsField } from '@when-fertility/shared/gql/graphql';
import { KitType, QUESTIONNAIRE_QUESTIONS } from './index';

class QuestionnaireQuestionsService {
  getQuestionnaireQuestions = (testName: KitType) => {
    return Object.values(QUESTIONNAIRE_QUESTIONS).find((questions) => questions.kit_type === testName)?.questions || [];
  };

  getIsProfileComplete = (questionnaireValues: GqlQuestionnaireDetailsField[] | null) => {
    if (!questionnaireValues) {
      return false;
    }

    const requiredQuiz = questionnaireQuestionsService.getQuestionnaireQuestions('OVARIAN_RESERVE').filter((q) => q.isRequiredToCompleteProfile);
    const allComplete = !requiredQuiz.find((q) => {
      let value = questionnaireValues.find((v) => v.key === q.key)?.value || questionnaireValues.find((v) => v.key === q.key)?.values;
      if (value && typeof value === 'string') {
        let key;
        [key, value] = this.questionnaireSubQuestionValue(questionnaireValues, q.key, value);
        if (key && value && typeof value === 'string') {
          [key, value] = this.questionnaireSubQuestionValue(questionnaireValues, key, value);
        }
      }
      const quizNotComplete = Boolean(!value);
      return quizNotComplete;
    });
    return allComplete;
  };

  convertDetailsToHash = (details: GqlQuestionnaireDetailsField[]) => {
    return details.reduce((acc, item) => {
      const { key, ...rest } = item;
      acc[key] = rest;
      return acc;
    }, {} as { [key: string]: Omit<GqlQuestionnaireDetailsField, 'key'> });
  };

  questionnaireSubQuestionValue = (profileValues: GqlQuestionnaireDetailsField[], key: string, value: string | undefined) => {
    const questionnaireQuestions = this.getQuestionnaireQuestions('OVARIAN_RESERVE');

    const requiredSubQuestions = questionnaireQuestions.filter((q) => q.showIfSelected);
    const requiredSub = requiredSubQuestions.find((sq) => sq?.showIfSelected?.[key] && sq?.showIfSelected?.[key]?.[0] == value);
    if (requiredSub) {
      const subValue = profileValues.find((v) => v.key === requiredSub.key);
      return [subValue?.key, subValue?.value];
    }
    return [key, value];
  };
}

export const questionnaireQuestionsService = new QuestionnaireQuestionsService();
