import { GqlResultsPageTestKitFragmentFragment } from '@when-fertility/shared/gql/graphql';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Button, Icon } from '@when-fertility/shared/domain/common';
import { useNavigate } from 'react-router-dom';
import { USER_ROUTES } from '../../user';
import { Tooltip } from './tooltip-component';
import { DownloadReportButton } from '@when-fertility/shared/domain/results';
import { questionnaireQuestionsService } from '@when-fertility/shared/domain/questionnaire-questions';
import productImagePath from '@when-fertility/shared/assets/images/product.png';
type Props = {
  testKit: GqlResultsPageTestKitFragmentFragment;
};

export const ResultListItem = ({ testKit }: Props) => {
  const navigate = useNavigate();
  const isProfileComplete = useMemo(
    () => Boolean(questionnaireQuestionsService.getIsProfileComplete(testKit?.questionnaire?.details || [])),
    [testKit]
  );

  return (
    <div
      className={`${
        testKit.status && !['CLOSED', 'CANCELLED'].includes(testKit.status) ? 'bg-white' : 'bg-silver-60'
      } flex gap-2 p-6 sm:px-10 sm:py-4 text-charcoal-80 border sm:border-t-0 border-silver-100 rounded-lg sm:rounded-none last:rounded-b-lg my-3 sm:m-0`}
    >
      <div className="w-1/3 sm:flex">
        <div className="w-full sm:w-1/5">
          <img src={productImagePath} width={47} height={63} alt={'product'} />
        </div>
        <div className="hidden sm:block w-4/5 p-5 text-base">
          {testKit.product?.title}
          {testKit.activationCode && process.env.SHOW_ACTIVATION_CODES === 'true' && (
            <div className="text-charcoal-60 text-xs">Activation code: {testKit.activationCode}</div>
          )}
        </div>
      </div>
      {/* mobile screen */}
      <div className="flex flex-col sm:hidden">
        <div className="block sm:hidden w-4/5 p-0 sm:p-6">{testKit.product?.title}</div>
        <div className="w-full sm:w-1/4">
          <div className="pb-3 sm:py-6">
            {testKit.dateSampleCollected && (
              <>
                <span className="sm:hidden">Sample collected at: </span>
                {testKit.dateSampleCollected && format(new Date(testKit.dateSampleCollected), 'dd MMMM yyyy')}
              </>
            )}
          </div>
        </div>
        <div className="border border-silver-100"></div>
        <div className="w-full sm:w-1/4 pt-3 sm:p-0">
          {testKit.status && !['CLOSED', 'CANCELLED'].includes(testKit.status) && testKit.resultsStatus === 'NOT_RECEIVED' && (
            <div className="text-charcoal-60 flex items-center">
              In progress
              <Tooltip text="Our lab is currently processing your results. We'll notify you when they're ready!">
                <Icon icon="info" className="ml-1" />
              </Tooltip>
            </div>
          )}
          {!isProfileComplete && (
            <Button isLink className="text-xs mt-6" onClick={() => navigate(USER_ROUTES.profileQuiz + `?testKitId=${testKit.id}`)}>
              Complete questionnaire
            </Button>
          )}
          {testKit.status !== 'CANCELLED' && testKit.resultsStatus !== 'NOT_RECEIVED' && (
            <div className="flex flex-col  items-start">
              <div className="mb-2">{isProfileComplete ? 'Your results are ready!' : 'Complete questionnaire to view report'}</div>
              {testKit.resultsStatus === 'RECEIVED_RELEASED' && (
                <>
                  <DownloadReportButton testKitId={testKit.id} reportType={'EGG_COUNT_REPORT'} />
                  <div className={'mt-2'}>
                    <DownloadReportButton testKitId={testKit.id} reportType={'HEALTH_PRACTITIONER_REPORT'} />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/* desktop screen */}
      <div className="hidden sm:block w-1/3">
        <div className="py-6 text-base">{testKit.dateSampleCollected ? format(new Date(testKit.dateSampleCollected), 'dd MMMM yyyy') : '-'}</div>
      </div>
      <div className="hidden sm:flex w-1/3 items-center text-base justify-center">
        {testKit.status && !['CLOSED', 'CANCELLED'].includes(testKit.status) && testKit.resultsStatus === 'NOT_RECEIVED' && (
          <div className="text-charcoal-60 flex items-center">
            In progress
            <Tooltip text="Our lab is currently processing your results. We'll notify you when they're ready!">
              <Icon icon="info" className="ml-1" />
            </Tooltip>
          </div>
        )}
        <div className="flex flex-col items-start">
          {testKit.status !== 'CANCELLED' && testKit.resultsStatus !== 'NOT_RECEIVED' && (
            <>
              {testKit.resultsStatus !== 'RECEIVED_RELEASED' && !isProfileComplete && (
                <>
                  <div className="mb-2">Complete questionnaire to view report</div>
                  <Button isLink className="text-xs mb-2" onClick={() => navigate(USER_ROUTES.profileQuiz + `?testKitId=${testKit.id}`)}>
                    Complete questionnaire
                  </Button>
                </>
              )}
              {testKit.resultsStatus === 'RECEIVED_RELEASED' && (
                <>
                  <div className="mb-2">Your results are ready!</div>
                  <DownloadReportButton testKitId={testKit.id} reportType={'EGG_COUNT_REPORT'} />
                  <div className={'mt-2'}>
                    <DownloadReportButton testKitId={testKit.id} reportType={'HEALTH_PRACTITIONER_REPORT'} />
                  </div>
                </>
              )}
            </>
          )}
          {!isProfileComplete && testKit.resultsStatus !== 'RECEIVED_RELEASED' && (
            <Button isLink className="ml-6 text-xs" onClick={() => navigate(USER_ROUTES.profileQuiz + `?testKitId=${testKit.id}`)}>
              Complete questionnaire
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
