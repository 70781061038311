import { GqlTestKit, GqlTestKitStatus } from '@when-fertility/shared/gql/graphql';
import { differenceInDays } from 'date-fns';

class TestKitService {
  getIsTestKitActivated = (testKitStatus: GqlTestKitStatus | null | undefined) =>
    Boolean(testKitStatus && ['SUBMITTED', 'OPEN', 'SPECIMEN_RECEIVED', 'ACTIVATED', 'CLOSED'].includes(testKitStatus));

  getLatestTestKit = <T>(
    testKits: (Pick<GqlTestKit, 'id' | 'status' | 'dateResultsReceived' | 'resultsStatus' | 'hasViewedResult'> & T)[] | undefined
  ) => {
    if (!testKits?.length) {
      return null;
    }

    const noResultsOrWithin7Days = (kit: Pick<GqlTestKit, 'id' | 'status' | 'dateResultsReceived' | 'resultsStatus' | 'hasViewedResult'>) => {
      if (!kit.hasViewedResult) {
        return true;
      }
      const delta = differenceInDays(new Date(), new Date(kit.dateResultsReceived));
      const result = kit.resultsStatus !== 'RECEIVED_RELEASED' || delta <= 7;
      return result;
    };
    const activatedTestKits = testKits.filter((kit) => this.getIsTestKitActivated(kit.status) && noResultsOrWithin7Days(kit));

    return activatedTestKits.length ? activatedTestKits[0] : null;
  };
}

export const testKitService = new TestKitService();
