import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import {
  GqlQuestionnaireDetailsInput,
  GqlQuestionnaireDetailsFieldKey,
  useQuestionnaireProviderQuestionnaireByTestKitIdQuery,
  useUpdateQuestionnaireMutation,
} from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import {
  QuestionnaireQuestion,
  QuestionnaireQuestionFieldKey,
  questionnaireQuestionsService,
} from '@when-fertility/shared/domain/questionnaire-questions';
import { useCurrentUser } from '@when-fertility/shared/domain/user/current-user.hook';

type Props = { testKitId: string; children: ReactNode; allowNextOnChange?: boolean; onFinish?: () => void; saveOnFinish?: boolean };
type FormDataValue = { value: string; values?: string[]; comment: string };

export type QuestionnaireContextType = {
  formData: Partial<Record<QuestionnaireQuestionFieldKey, FormDataValue>>;
  questionnaireQuestions: QuestionnaireQuestion[];
  updateField: ({ id, value }: { id: string; value: FormDataValue }) => void;
  updateFieldAndGoNext: ({ id, value }: { id: string; value: FormDataValue }) => void;
  currentQuestion: QuestionnaireQuestion;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  allowNextOnChange: boolean;
  setCurrentQuestion: (value: QuestionnaireQuestion) => void;
  prettyPrintAnswer: (value: QuestionnaireQuestion) => string | null;
  resetFormData: () => void;
  canShowQuestion: ({
    _formData,
    question,
  }: {
    _formData?: Partial<Record<QuestionnaireQuestionFieldKey, string>>;
    question: QuestionnaireQuestion;
  }) => boolean;
  next: () => void;
  prev: () => void;
  save: () => void;
  quizProgressPercentage: number;
  errorMessage?: string;
};

export const QuestionnaireContext = createContext<QuestionnaireContextType | null>(null);

const questionnaireQuestions = questionnaireQuestionsService.getQuestionnaireQuestions('OVARIAN_RESERVE');

export const QuestionnaireProvider = ({ testKitId, children, allowNextOnChange = true, onFinish, saveOnFinish }: Props) => {
  const [formData, setFormData] = useState<Partial<Record<QuestionnaireQuestionFieldKey, string>>>({});
  const [currentQuestion, setCurrentQuestion] = useState<QuestionnaireQuestion>(questionnaireQuestions[0]);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const isFirstQuestion = useMemo(() => currentQuestionId - 1 < 0, [currentQuestionId, questionnaireQuestions]);
  const isLastQuestion = useMemo(() => currentQuestionId + 1 === questionnaireQuestions?.length, [currentQuestionId, questionnaireQuestions]);
  const [saveQuestionnaire, { loading: isSavingQuestionnaire, error: isErrorUpdatingQuestionnaire }] = useUpdateQuestionnaireMutation();
  const { loggedInUserId } = useCurrentUser();

  const canShowQuestion = ({
    _formData,
    question,
  }: {
    _formData?: Partial<Record<QuestionnaireQuestionFieldKey, string>>;
    question: QuestionnaireQuestion;
  }) => {
    if (!question?.showIfSelected) {
      return true;
    }

    const formDataTemp = _formData || formData;

    const keys = Object.keys(question.showIfSelected);

    return Boolean(
      keys.find((key) => {
        const showIfSelectedValues = question.showIfSelected ? question.showIfSelected[key] : [];
        return showIfSelectedValues?.includes(formDataTemp[key as QuestionnaireQuestionFieldKey]?.value || '');
      })?.length
    );
  };

  /**
   * TODO: this function currently only does checks starting from a given index in the questionnaireQuestions array
   * It will find the first question it can display and stop iterating after that.
   * In the future we might want a version of this where we can parse the entire formData instead.
   */
  const processFormDataUpdate = ({
    questionIdToStartFrom = currentQuestionId,
    _formData,
    nextOrPrev = 'next',
  }: {
    questionIdToStartFrom?: number;
    _formData: Partial<Record<QuestionnaireQuestionFieldKey, string>>;
    nextOrPrev?: 'next' | 'prev';
  }): { id: number; formData: Partial<Record<QuestionnaireQuestionFieldKey, string>> } => {
    const currentQuestionIdTemp = nextOrPrev === 'next' ? questionIdToStartFrom + 1 : questionIdToStartFrom - 1;
    let formDataTemp = _formData;
    const nextQuestionTemp = questionnaireQuestions[currentQuestionIdTemp];

    if (isLastQuestion) {
      return { id: -1, formData: formDataTemp };
    }

    //check if can show the next question
    if (canShowQuestion({ _formData, question: nextQuestionTemp })) {
      return { id: currentQuestionIdTemp, formData: formDataTemp };
    }
    // Can't show this question; clear the formData for this question
    formDataTemp = { ...formDataTemp, [nextQuestionTemp.key]: '' };

    const isNextQuestionLastQuestion = currentQuestionIdTemp + 1 === questionnaireQuestions?.length;
    if (isNextQuestionLastQuestion) {
      return { id: -1, formData: formDataTemp };
    }
    // Keep looking
    return processFormDataUpdate({ questionIdToStartFrom: currentQuestionIdTemp, _formData: formDataTemp, nextOrPrev });
  };

  const updateField = ({ id, value }: { id: string; value: string }) => {
    // If it's the last question, no need to process following questions as there aren't any

    if (isLastQuestion) {
      setFormData({ ...formData, [id]: value });
      return;
    }

    const { formData: updatedFormData } = processFormDataUpdate({
      questionIdToStartFrom: questionnaireQuestions.findIndex((q) => q.key === currentQuestion.key),
      _formData: { ...formData, [id]: value },
    });
    setFormData(updatedFormData);
  };

  const {
    data: questionnaireData,
    loading,
    error,
  } = useQuestionnaireProviderQuestionnaireByTestKitIdQuery({
    ...{ variables: { input: { id: testKitId } } },
    onCompleted: (data) => {
      if (!data?.questionnaireByTestKitId?.details) {
        return;
      }
      const questionnaireData: Partial<Record<GqlQuestionnaireDetailsFieldKey, string>> = {};

      data.questionnaireByTestKitId.details.forEach((questionnaireField) => {
        questionnaireData[questionnaireField.key] = questionnaireField;
      });
      console.log(questionnaireData);
      setFormData(questionnaireData);
    },
    skip: !loggedInUserId || !testKitId,
    fetchPolicy: 'cache-and-network',
  });
  const questionnaire = useMemo(() => questionnaireData?.questionnaireByTestKitId, [questionnaireData]);

  const handleFinish = async (updatedFormData: Partial<Record<QuestionnaireQuestionFieldKey, string>>) => {
    if (saveOnFinish) {
      await save(updatedFormData, () => {
        if (onFinish) {
          onFinish();
        }
      });
      return;
    }

    if (onFinish) {
      onFinish();
    }
  };

  const next = async () => {
    if (isLastQuestion) {
      handleFinish(formData);
      return;
    }

    const { id: nextQuestionId } = processFormDataUpdate({
      questionIdToStartFrom: currentQuestionId,
      _formData: formData,
      nextOrPrev: 'next',
    });

    if (nextQuestionId !== -1) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestion(questionnaireQuestions[nextQuestionId]);
      return;
    }
    handleFinish(formData);
  };

  const prev = () => {
    if (isFirstQuestion) {
      return;
    }

    const { id: nextQuestionId } = processFormDataUpdate({
      questionIdToStartFrom: currentQuestionId,
      _formData: formData,
      nextOrPrev: 'prev',
    });
    if (nextQuestionId !== -1) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestion(questionnaireQuestions[nextQuestionId]);
    }
  };

  const updateFieldAndGoNext = async ({ id, value }: { id: string; value: string }) => {
    const newFormData = { ...formData, [id]: value };
    const { formData: updatedFormData, id: nextQuestionId } = processFormDataUpdate({
      questionIdToStartFrom: currentQuestionId,
      _formData: newFormData,
      nextOrPrev: 'next',
    });
    setFormData({ ...formData, ...updatedFormData });

    if (isLastQuestion) {
      handleFinish(updatedFormData);
    }

    if (nextQuestionId !== -1) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestion(questionnaireQuestions[nextQuestionId]);
    } else {
      handleFinish(updatedFormData);
    }
  };

  const save = async (updatedFormData: Partial<Record<QuestionnaireQuestionFieldKey, string>> = formData, onSuccess?: () => void) => {
    const keys = Object.keys(updatedFormData);
    const values = Object.values(updatedFormData);

    const parsedQuestionnaire: GqlQuestionnaireDetailsInput[] = [];

    keys.forEach((key, count) => {
      if (!key || !value) {
        return;
      }
      if (Array.isArray(values[count].values)) {
        parsedQuestionnaire.push({ key: key as GqlQuestionnaireDetailsFieldKey, comment: values[count].comment, values: values[count].values });
      } else {
        parsedQuestionnaire.push({ key: key as GqlQuestionnaireDetailsFieldKey, comment: values[count].comment, value: values[count].value });
      }
    });

    await saveQuestionnaire({
      variables: { input: { testKitId: testKitId, details: parsedQuestionnaire } },
      ...(onSuccess && { onCompleted: onSuccess }),
    });
  };

  const resetFormData = () => {
    if (!questionnaireData?.patientById.details) {
      return;
    }
    const questionnaireData: Partial<Record<GqlQuestionnaireDetailsFieldKey, string>> = {};

    questionnaireData?.patientById.details.forEach((questionnaireField) => {
      questionnaireData[questionnaireField.key] = questionnaireField;
    });

    setFormData(questionnaireData);
  };

  const prettyPrintAnswer = (question: QuestionnaireQuestion) => {
    if (!formData[question.key]?.value && !formData[question.key]?.values) {
      return null;
    }
    if (!question.options) {
      return formData[question.key]?.value || '-';
    }
    const comment = formData[question.key].comment || '';
    if (Array.isArray(formData[question.key].values)) {
      const multiSelectOptions = formData[question.key].values.map((key) => `- ${question.options[key || '']}`).join('\n') || '-';
      return `${multiSelectOptions} \n\n Comment: ${comment}`;
    }

    const answerText = question.options[formData[question.key]?.value || ''] || '-';
    return `${answerText} ${comment ? ' - ' + comment : ''}`;
  };

  const value = useMemo(
    () => ({
      formData,
      questionnaireQuestions,
      updateField,
      updateFieldAndGoNext,
      currentQuestion,
      isFirstQuestion,
      isLastQuestion,
      allowNextOnChange,
      setCurrentQuestion,
      prettyPrintAnswer,
      resetFormData,
      canShowQuestion,
      next,
      prev,
      save,
      quizProgressPercentage: (currentQuestionId / questionnaireQuestions.length) * 100,
      errorMessage: isErrorUpdatingQuestionnaire ? 'Error saving update' : '',
    }),
    [formData, currentQuestion]
  );

  if (error) {
    return <div>Error loading questionnaire</div>;
  }

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <QuestionnaireContext.Provider value={value}>
      {children}
      {isSavingQuestionnaire && <div>Saving...</div>}
      {isErrorUpdatingQuestionnaire && <div>Error saving questionnaire</div>}
    </QuestionnaireContext.Provider>
  );
};

export const useQuestionnaire = () => {
  const context = useContext(QuestionnaireContext);

  if (!context) {
    throw new Error('This component must be used within a <QuestionnaireProvider> component.');
  }

  return context;
};

QuestionnaireProvider.fragments = {
  questionnaire: gql`
    fragment QuestionnaireProviderQuestionnaireFragment on Questionnaire {
      id
      userId
      testKitId
      details {
        key
        value
        values
        comment
      }
    }
  `,
};

QuestionnaireProvider.mutation = gql`
  mutation UpdateQuestionnaire($input: CreateOrUpdateQuestionnaireInput!) {
    createOrUpdateQuestionnaire(input: $input) {
      id
    }
  }
`;

QuestionnaireProvider.query = {
  questionnaireById: gql`
    ${QuestionnaireProvider.fragments.questionnaire}

    query QuestionnaireProviderQuestionnaireByTestKitId($input: ByIdInput) {
      questionnaireByTestKitId(input: $input) {
        ...QuestionnaireProviderQuestionnaireFragment
      }
    }
  `,
};
